<template>
  <div class="project-container">
    <div class="divider">特色项目</div>
    <div class="project">
      <!-- <div v-for="item in data" :key="item.id" class="project-item">
        <div class="div1">
          <img style="width: 476px; height: 302px" :src="item.url" alt="" />
        </div>
        <div class="div2">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="div3">
          <p>{{ item.content }}</p>
        </div>
      </div> -->
      <swiper
        :modules="modules"
        :navigation="true"
        :slidesPerView="3"
        :spaceBetween="0"
        :loop="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide v-for="item in data" :key="item.id">
          <div class="project-item">
            <div class="div1">
              <img
                style="width: 476px; height: 302px"
                :src="item.url"
                alt="中医疗法"
              />
            </div>
            <div class="div2">
              <h3>{{ item.title }}</h3>
            </div>
            <div class="div3">
              <p>{{ item.content }}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { ref, onMounted } from "vue";
import { api } from "@/api/specialProject/index.js";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const modules = ref([Pagination, Navigation, Autoplay]);

const data = ref([]);

onMounted(() => {
  api()
    .getList()
    .then((res) => {
      data.value = res.data;
    });
});
</script>

<style lang="scss" scoped>
.project-container {
  width: 100%;
  height: 695px;
  background: rgb(229, 247, 247);
  // overflow: auto;
  .divider {
    width: 100%;
    height: 80px;
    background-color: rgb(229, 247, 247);
    font-size: 20px;
    color: #787676;
    text-align: center;
    // margin: 40px 40px 40px 0;
    line-height: 80px;
    font-weight: bold;
    letter-spacing: 9px;
    // border: #000 1px solid;
  }
  .project {
    display: flex; // 弹性布局
    width: 1426px;
    height: 616px;
    margin: 0 auto;
    background-color: #fff;
    // overflow-x: auto; /* 添加水平滚动条 */
    // overflow-y: hidden; /* 隐藏垂直滚动条 */
    // border: #000 1px solid;
    .project-item {
      display: inline-block;
      width: 474px;
      height: 616px;
      // margin: 0 auto;
      background-color: #fff;
      // border: #5da2e0 1px dashed;
      .div1 {
        width: 476px;
        height: 302px;
        border: #5da2e0 1px dashed;
      }
      .div2 {
        width: 474px;
        height: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        // border: #000 1px solid;
        h3 {
          font-size: 26px;
          color: #777576;
          margin-top: 20px;
          text-align: center; /* 字体居中 */
        }
      }
      .div3 {
        width: 474px;
        height: 239px;
        // border: #5da2e0 1px dashed;
        p {
          height: 239px;
          font-size: 20px;
          color: #959595;
          line-height: 30px;
          margin: 0 20px;
          text-overflow: ellipsis; // 使用省略号表示被截断的文本
          white-space: pre-wrap; //  连续的空格会被保留
          // font-weight: bold; /* 设置文本为粗体 */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          overflow: hidden;
        }
      }
    }
    .swiper {
      height: 100%;
    }
    :deep(.swiper-button-prev) {
      color: #00b2b3;
    }
    :deep(.swiper-button-next) {
      color: #00b2b3;
    }
  }
}
</style>
