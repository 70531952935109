import request from "@/utils/request";

/**
 * 特色专病接口
 *
 */
export function characteristicDepartmentApi() {
  return {
    get: () => {
      return request({
        url: "/website/specializedSubject/front/specialize/list",
        method: "get",
      });
    },
    getList: (id) => {
      return request({
        url:
          "/website/specializedSubject/front/specialize/detailSpecialize/" + id,
        method: "get",
      });
    },
  };
}
