<template>
  <div class="builder-container">
    <div class="content-contianer">
      <TitleHeader title="党建工作" />

      <div class="list-container">
        <swiper
          :modules="modules"
          :navigation="true"
          :slidesPerView="4"
          :spaceBetween="0"
          :loop="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide
            class="swiper-slide"
            v-for="item in list"
            :key="item.id"
          >
            <!-- <div v-for="itme in list" :key="itme.id"> -->
            <div class="list-item">
              <img :src="item.url" alt="党建工作" />
              <div class="title-container">{{ item.title }}</div>
            </div>
            <!-- </div> -->
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import TitleHeader from "@/views/component/TitleHeader.vue";
import { ref, onMounted } from "vue";
import { api } from "@/api/partyBuild/index.js";

const modules = ref([Pagination, Navigation, Autoplay]);

const list = ref([]);

onMounted(() => {
  api()
    .getList()
    .then((res) => {
      list.value = res.data;
    });
});
</script>

<style lang="scss" scoped>
.builder-container {
  width: 100%;
  height: 348px;
  background-color: rgb(229, 247, 247);
  overflow: hidden;
  .content-contianer {
    width: 1424px;
    margin: 0 auto;
    margin-top: 20px;

    .list-container {
      display: flex;
      .list-item {
        position: relative;
        width: 317px;
        height: 227px;
        background: #98d7d5;
        border: 1px solid #a9abaf;
        margin: 0 22px;
        img {
          width: 100%;
          height: 100%;
        }
        .title-container {
          width: 314px;
          position: absolute;
          background: #ffff;
          bottom: 0;
          height: 45px;
          line-height: 45px;
          padding-left: 15px;
          color: #787676;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
