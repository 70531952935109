<template>
  <div class="map-container">
    <el-amap
      :center="center"
      :zoomEnable="false"
      :zoom="zoom"
      :showIndoorMap="false"
      @init="init"
      @click="add"
    >
      <el-amap-marker
        :position="componentMarker2.position"
        :content="componentMarker2.content"
      />
    </el-amap>
  </div>
</template>

<script setup>
import { ref } from "vue";
import VueAMap, {
  initAMapApiLoader,
  ElAmap,
  ElAmapMarker,
} from "@vuemap/vue-amap";
import "@vuemap/vue-amap/dist/style.css";
initAMapApiLoader({
  key: "6d7aa74c2c7b7fbbdbfcd9be744e7f80",
  securityJsCode: "23a29411e0fbbb2d8616e7d00134fc76", // 新版key需要配合安全密钥使用
  //Loca:{
  //  version: '2.0.0'
  //} // 如果需要使用loca组件库，需要加载Loca
});

let zoom = ref(16.79);
let center = ref([104.009541, 30.673242]);
let mapNode = ref(null);
const init = (map) => {
  const marker = new AMap.Marker({
    position: [104.009594, 30.673706],
  });
  //  清空map
  map.add(marker);
  mapNode.value = map;
};

const add = (e) => {
  const marker = new AMap.Marker({
    position: [e.lnglat.lng, e.lnglat.lat],
  });
  mapNode.value.add(marker);
};

const componentMarker2 = ref({
  position: [104.009154, 30.673706],
  content:
    "<div style='color:#AD2D2D;width: 90px;text-align: center;font-weight: bold'>百安国医馆</div>",
});

// const clearMarkers = () => {
//   markers.value = markers.value.filter((marker) => {
//     const name = marker.content.innerText;
//     return !name.includes("中药") && !name.includes("医馆");
//   });
//   markers.value.forEach((marker) => mapNode.value.remove(marker));
// };
</script>

<style lang="scss" scoped>
.map-container {
  width: 1424px;
  height: 300px;
  margin: 0 auto 40px;
}
</style>
