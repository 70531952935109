import request from "@/utils/request";

/**
 * 专家接口
 *
 */
export function specialistApi() {
  return {
    getSpecialist: () => {
      return request({
        url: "/website/doctor/front/list",
        method: "get",
      });
    },
    searchByCase: (keyword) => {
      console.log(keyword);
      return request({
        url: "/website/doctor/front/searchByCase",
        method: "get",
        params: {
          keyword: keyword,
        },
      });
    },
  };
}
