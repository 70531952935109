<template>
  <el-page-header :icon="null" :title="title">
    <template #extra>
      <div @click="jumpMore">
        <!-- <span style="color:#787676; vertical-align: middle;font-size: 16px;">更多</span>
        <el-icon color="#787676" style="vertical-align: middle;">
          <DArrowRight />
        </el-icon> -->
      </div>
    </template>
  </el-page-header>
   <el-divider />
</template>

<script setup>
import { defineProps } from "vue";
import { DArrowRight } from "@element-plus/icons-vue";
const props = defineProps({
  title: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["more"]);

const jumpMore = () => {
  emit("more");
};
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 20px 0;
}
</style>