<template>
  <div class="environment-container">
    <div class="content-contaier">
      <TitleHeader title="医馆环境" />

      <div class="show-container">
        <div class="first-show">
          <img :src="img1" alt="百安国医馆环境" />
        </div>

        <div class="right-show">
          <div v-for="item in img2" :key="item.id">
            <img :src="item.url" alt="百安国医馆环境" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TitleHeader from "@/views/component/TitleHeader.vue";
import { hospitalEnvironmentApi } from "@/api/hospitalEnvironment/hospitalEnvironment.js";
import { ref, onMounted } from "vue";

let img1 = ref("");
let img2 = ref([]);

onMounted(() => {
  hospitalEnvironmentApi()
    .get()
    .then((res) => {
      const result = res.data;
      img1 = result[0].url;
      img2.value = result.slice(1);
    });
});
</script>

<style lang="scss" scoped>
.environment-container {
  width: 100%;
  .content-contaier {
    width: 1424px;
    margin: 12px auto 30px;
    .show-container {
      display: flex;
      height: 480px;
      .first-show {
        width: 450px;
        height: 480px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .right-show {
        margin-left: 20px;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        img {
          width: 100%;
          height: 229px;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
