import request from "@/utils/request";

/**
 * 轮播图接口
 *
 */
export function bannerApi() {
  return {
    getList: () => {
      return request({
        url: "/website/slideshow/front/getShowFlag",
        method: "get",
      });
    },
  };
}
