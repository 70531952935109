<template>
  <div class="department-container" v-if="list">
    <div class="depart-img">
      <img style="width: 100%; height: 100%" :src="img" alt="胡氏儿科" />
    </div>
    <div class="msg-list">
      <div class="list-item" v-for="itme in list" :key="itme.id">
        <div class="item-container">
          <p class="header">{{ itme.title }}</p>
          <div class="content">
            {{ itme.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { api } from "@/api/huShiChildDepart/index.js";

const list = ref([]);
const img = ref("");
onMounted(() => {
  api()
    .getList()
    .then((res) => {
      if (Array.isArray(res.data) && res.data.length !== 0) {
        list.value = res.data;
      }
    });
  api()
    .getImg()
    .then((res) => {
      if (res.data !== null && res.data !== "") {
        img.value = res.data.url;
      }
    });
});
</script>

<style lang="scss" scoped>
.department-container {
  width: 1424px;
  margin: 42px auto;
  display: flex;
  .depart-img {
    flex-basis: 644px;
    height: 748px;
  }

  .msg-list {
    margin-left: 70px;
    flex: 1;
    height: 748px;
    background-color: #00b2b3;
    padding: 0 12px;
    overflow: auto;
    .list-item {
      display: flex;
      justify-content: space-between;
      min-height: 235px;
      border-bottom: 1px solid #d6d6d6;
      flex-direction: column;
      .item-container {
        flex: 1;

        .header {
          font-size: 30px;
          font-weight: bold;
          margin-top: 41px;
          color: #ffffff;
        }
        .content {
          display: -webkit-box;
          margin-top: 10px;
          font-size: 18px;
          color: #ffffff;
          font-weight: 500;
          line-height: 40px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .switch {
          text-align: center;
          color: #fff;
          cursor: pointer;
          label {
            cursor: pointer;
          }
          label input[name="switch"] {
            display: none;
          }
        }
        &:has(input[name="switch"]:checked) .content {
          -webkit-line-clamp: 9999;
        }
      }
    }
  }
}
</style>
