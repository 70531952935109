import request from "@/utils/request";

/**
 * 胡氏儿科接口
 *
 */
export function api() {
  return {
    getImg: () => {
      return request({
        url: "/website/hu/front/showOne",
        method: "get",
      });
    },
    getList: () => {
      return request({
        url: "/website/hu/front/list",
        method: "get",
      });
    },
  };
}
