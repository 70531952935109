import request from "@/utils/request";

/**
 * 党建工作接口
 *
 */
export function api() {
  return {
    getList: () => {
      return request({
        url: "/website/architecture/front/list",
        method: "get",
      });
    },
  };
}
