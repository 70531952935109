import request from "@/utils/request";

/**
 * 医院简介接口
 *
 */
export function hospitalIntroduceApi() {
  return {
    get: () => {
      return request({
        url: "/website/hospital/front/show",
        method: "get",
      });
    },
  };
}
