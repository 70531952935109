<template>
  <div class="server-container">
    <div class="divider">便民服务</div>
    <el-scrollbar style="height: 212px">
      <div class="serve-list">
        <div class="list-item">
          <div class="project-img">
            <img
              :src="require('@/assets/home/bookingRegister.png')"
              alt="预约挂号"
            />
          </div>
          <p class="serve-name">预约挂号</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img
              :src="require('@/assets/home/medicalInsurance.png')"
              alt="医保报销"
            />
          </div>
          <p class="serve-name">医保报销</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img :src="require('@/assets/home/Brewing.png')" alt="中药代煎" />
          </div>
          <p class="serve-name">中药代煎</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img
              :src="require('@/assets/home/DeliverGoods.png')"
              alt="中药代发"
            />
          </div>
          <p class="serve-name">中药代发</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img :src="require('@/assets/home/Following.png')" alt="自主续方" />
          </div>
          <p class="serve-name">自主续方</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img
              :src="require('@/assets/home/OnlineConsultation.png')"
              alt="在线咨询"
            />
          </div>
          <p class="serve-name">在线咨询</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img
              :src="require('@/assets/home/MaternalAndChildService.png')"
              alt="母婴服务"
            />
          </div>
          <p class="serve-name">母婴服务</p>
        </div>
        <div class="list-item">
          <div class="project-img">
            <img :src="require('@/assets/home/traffic.png')" alt="交通指南" />
          </div>
          <p class="serve-name">交通指南</p>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.server-container {
  margin-top: 40px;
  width: 100%;
  height: 277px;
  background-color: rgb(229, 247, 247);
  border: 1;
  .divider {
    width: 100%;
    height: 44px;
    background-color: rgb(229, 247, 247);
    font-size: 20px;
    color: #787676;
    text-align: center;
    margin: 28px 0 0;
    line-height: 44px;
    font-weight: bold;
    letter-spacing: 9px;
  }

  .serve-list {
    width: 1424px;
    height: 187px;
    margin: 22px auto 0;
    display: flex;
    justify-content: space-around;

    .list-item {
      width: 94px;
      flex-shrink: 0;
      margin: 0 20px;
      .project-img {
        width: 100%;
        height: 94px;

        border-radius: 13px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 13px;
        }
      }

      .serve-name {
        margin-top: 15px;
        color: #787676;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
