import request from "@/utils/request";

/**
 * 名医讲堂接口
 *
 */
export function api() {
  return {
    getList: () => {
      return request({
        url: "/website/doctorClass/front/list",
        method: "get",
      });
    },
  };
}
