import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: HomeView,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/HospitalIntroduce",
        name: "HospitalIntroduce",
        component: () => import("@/views/home/HospitalIntroduce.vue"),
      },
      {
        path: "/News",
        name: "News",
        component: () => import("@/views/home/News.vue"),
      },
      {
        path: "/Doctor",
        name: "Doctor",
        component: () => import("@/views/home/Doctor.vue"),
      },
      {
        path: "/CharacteristicDepartment",
        name: "CharacteristicDepartment",
        component: () => import("@/views/home/CharacteristicDepartment.vue"),
      },
      {
        path: "/HushiChild",
        name: "HushiChild",
        component: () => import("@/views/home/HuShiChildDepart.vue"),
      },
      {
        path: "/SpecialProject",
        name: "SpecialProject",
        component: () => import("@/views/home/SpecialProject.vue"),
      },
      {
        path: "/HandyServe",
        name: "HandyServe",
        component: () => import("@/views/home/HandyServe.vue"),
      },
      {
        path: "/FamousDoctor",
        name: "FamousDoctor",
        component: () => import("@/views/home/FamousDoctorClass.vue"),
      },
      {
        path: "/PartyBuild",
        name: "PartyBuild",
        component: () => import("@/views/home/PartyBuild.vue"),
      },
      {
        path: "/HospitalEnvironment",
        name: "HospitalEnvironment",
        component: () => import("@/views/home/HospitalEnvironment.vue"),
      },
      {
        path: "/Map",
        name: "Map",
        component: () => import("@/views/home/Map.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/home/aboutUs.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
