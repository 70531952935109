<template>
  <div class="footer-container">
    <div class="cotent-container">
      <div class="divider-top"></div>
      <div class="hosipital-msg">
        <div class="item1">
          <div class="note-img"></div>
          <div class="archival-info">
            <p class="font">版权所有： 百安国医馆</p>
            <p class="font" style="margin-top: 5px">
              备案号：蜀ICP备2022013475号-1
            </p>
          </div>
        </div>
        <div class="item2">
          <p class="font">医馆地址：四川省成都市青羊大道203号百安国医馆</p>
          <p class="font" style="margin-top: 10px">咨询电话：028-62323889</p>
          <!-- <p class="font" style="margin-top: 10px">
            患者咨询：19980469890 17711568682
          </p> -->
          <p class="font" style="margin-top: 10px">监督热线：19980477112</p>
          <p class="font" style="margin-top: 10px">
            意见邮箱：2670341783@qq.com
          </p>
        </div>
        <div class="item3">
          <div class="code-item">
            <img
              class="code-img"
              :src="require('@/assets/home/customerService1.png')"
              alt="咨询客服"
            />
            <p class="code-text">咨询客服1</p>
          </div>
          <div class="code-item">
            <img
              class="code-img"
              :src="require('@/assets/home/customerService2.jpg')"
              alt="咨询客服"
            />
            <p class="code-text">咨询客服2</p>
          </div>
          <div class="code-item">
            <img
              class="code-img"
              :src="require('@/assets/home/mp-weixin.png')"
              alt="微信公众号"
            />
            <p class="code-text">微信公众号</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.footer-container {
  height: 257px;
  background: #00b2b3;
  .cotent-container {
    width: 1424px;
    margin: 0 auto;
    overflow: auto;
    .divider-top {
      margin-top: 40px;
      height: 1px;
      background-color: #ffffff;
    }

    .hosipital-msg {
      margin-top: 47px;
      display: flex;
      .item1 {
        flex: 1;
        display: flex;
        .note-img {
          width: 40px;
          height: 40px;
          background: url("@/assets/home/g_a.png");
          background-size: 100% 100%;
        }
        .archival-info {
          margin-left: 10px;
        }
      }
      .font {
        font-size: 14px;
        color: #ffffff;
      }
      .item2 {
        flex: 1;
      }
      .item3 {
        flex: 1;
        display: flex;
        justify-content: space-around;
        .code-item {
          .code-img {
            width: 75px;
            height: 75px;
          }

          .code-text {
            margin-top: 10px;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
