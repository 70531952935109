import request from "@/utils/request";

/**
 * 新闻接口
 *
 */
export function newsApi() {
  return {
    getNews: () => {
      return request({
        url: "/website/news/front/list",
        method: "get",
      });
    },
    getBanne: () => {
      return request({
        url: "/website/news/front/getNewsSlideshowList",
        method: "get",
      });
    },
  };
}
